import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://a5890b92ffdc014ad6b74ef7662262e5@o4507828625932288.ingest.de.sentry.io/4507828632682576",
  debug: false,
  environment: "production",
  release: "www@1.0.0",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()